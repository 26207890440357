import React, { useCallback, useState } from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"
import { GetADemoForm } from "@components/forms/get-a-demo"
import { DbtFaq } from "@components/pages/integrations/dbt-faq"
import { SemanticLayerContent } from "@components/pages/integrations/semantic-layer-content"
import { SemanticLayerHeader } from "@components/pages/integrations/semantic-layer-header"

const MicrositeSemanticLayer = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <Layout
      title={`Unlock the value of metrics from your semantic layer for everyone`}
      description={`Turn your semantic layer projects into a metric catalog for
      business users to confidently analyze, visualize or build dashboards.`}
      fullWidth
      marginless
      hideOverflow
      microsite
    >
      <GetADemoForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Container fullWidth noPadding>
        <SemanticLayerHeader generic microsite />

        <SemanticLayerContent generic />

        <DbtFaq handleClick={handleClick} microsite />

        <PowerMetricsEOPCta
          microsite
          heading={"Make analytics everybody's business."}
          description={"Get started with a free account."}
        />
      </Container>
    </Layout>
  )
}

export default MicrositeSemanticLayer
